import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Home.scss";
import { connect } from "react-redux";
import { setMethod, setChannel, setPayData, setTradeStatus, setPaypalCreditCard, setTradeInfo } from "../redux/actions";
import Checkbox from "@material-ui/core/Checkbox";
import { PuffLoader } from "react-spinners";
import PaymentMethod from "./PaymentMethod";
import TimeIcon from "../img/time_small.png";
import PagsmileIcon from "../img/pagsmile_logo.png";
import Step1Act from "../img/step1_act.png";
import Step1Pas from "../img/step1_pas.png";
import Step2Act from "../img/step2_act.png";
import Step2Pas from "../img/step2_pas.png";
import Step3Pas from "../img/step3_pas.png";
import Step3Act from "../img/step3_act.png";
import Warning from "../img/warning.png";
import Close from "../img/close_gray.png";
import SortDown from "../img/sort_down.png";
import RightArrowBlack from "../img/right_arrow_black.png";
import Channel from "./Channel";
import Name from "./form-input/Name";
import TaxId from "./form-input/TaxId";
import Email from "./form-input/Email";
import PaymentMethodMobile from "./PaymentMethodMobile";
import CreditCardExpTime from "./form-input/CreditCardExpTime";
import CreditCardNumber from "./form-input/CreditCardNumber";
import TitleWithDetails from "./TitleWithDetails";
import TimeRemaining from "./TimeRemaining";
import req from "../utils/axios";
import { useTranslation } from "react-i18next";
import { useMethodConfig, channelConfig, countryCode2TaxIdType } from "../utils/configurations";
import PrivacyPolicy from "./PrivacyPolicy";
import { jsonParse } from "../utils/jsonParse";
import State from "./form-input/State";
import City from "./form-input/City";
import PostalCode from "./form-input/PostalCode";
import StreetAndNumber from "./form-input/StreetAndNumber";
import * as val from "../utils/validations";
import { tokenize, creditcardTokenize } from "../utils/tokanization";
import PaypalButton from "./PaypalButton";
import { toast } from "react-toastify";
import { issuer, formatMethod, FormatMoney, formatTime, hidedCardNo } from "../utils/formats";
import getFromConfig from "./form-input/Config/formConfig";
// import Nip from "./form-input/todito/Nip";
import CardNo from "./form-input/todito/CardNo";
import PinNo from "./form-input/todito/PinNo";
import ChannelSelect from "./Peru/ChannelSelect";
import Installment from "./form-input/Installment";
import PaypalCreditcard from "./form-input/Paypal-creditcard/PaypalCreditcard";
import Source from "./form-input/Source";
import Cep from "./form-input/Boleto/Cep";
import MobilePhone from "./form-input/MobilePhone";
import Address from "./form-input/Address/Address";
// 输入组件
import EllipsisScroll from "./EllipsisScroll";
import { returnUrlAsParam } from "../utils/queryUrl";
import { setOpenpayAcoount } from "../envs/env";
import HiddenForm from "./form-input/Openpay-creditcard/HiddenForm";
import AccountNumber from "./form-input/Localpayment/AccountNumber";
import BankList from "./form-input/Localpayment/BankList";
import countryList from "../utils/regionCodes";
import VisaCard from "../img/visa_card.png";
import MasterCard from "../img/master_card.png";
import Amex from "../img/amex.png";
import AddressBox from "./form-input/AddressBox";
import FirstName from "./form-input/FirstName";
import LastName from "./form-input/LastName";
import BankListPopUp from "./form-input/BankListPopUp";
import country2codes from "../utils/regionCodes";
import Credimax from "../components/form-input/CrediMax/CrediMax";
import CircularProgress from "@material-ui/core/CircularProgress";
import SubmitForm from "../components/form-input/SubmitPay/SubmitForm";

var Scroll = require("react-scroll");
var scroller = Scroll.scroller;
var classNames = require("classnames");

function Home(props) {
  const notify = (text) => {
    toast.warn(text, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const { t } = useTranslation();
  const methodConfig = useMethodConfig();
  const [focusOnInput, setFocusOnInput] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);
  const [selectNotice, setSelectNotice] = useState({ state: false, notice: "", callBack: null });
  const [isChecked, setIsChecked] = useState(true);
  const [dropDownState, setDropDownState] = useState(false);
  const [footerDropDownState, setFooterDropDownState] = useState(true);
  const paypalChildRef = useRef();
  const paypalChildRefMobile = useRef();
  const crediMaxChildRef = useRef();
  const [submitData, setSubmitData] = useState("");
  let history = useHistory();

  var [isLoading, setIsloading] = useState(false);
  var [isDiabled, setIsDisabled] = useState(false);
  const disablePay = () => {
    setIsDisabled(true);
  };

  const closePolicy = () => {
    setShowPolicy(false);
  };
  const [form, setForm] = useState({
    isGlobal: false,
    name: { value: "", valid: true, max: 80, checked: false, validityFunction: val.checkName },
    firstName: { value: "", valid: true, max: 80, checked: false, validityFunction: val.checkFirstName },
    lastName: { value: "", valid: true, max: 80, checked: false, validityFunction: val.checkLastName },
    idType: "",
    taxId: { value: "", valid: true, checked: false, validityFunction: val.checkTaxId },
    email: { value: "", valid: true, max: 80, checked: false, validityFunction: val.checkEmail },
    phone: {
      value: "",
      valid: true,
      cCode: "",
      countryObj: {},
      max: {
        BRA: 17,
        MEX: 16,
        CHL: 15,
        COL: 15,
        PER: 15,
        ECU: 16,
        PAN: 14,
        CRI: 14,
        SLV: 14,
        GTM: 14,
        NIC: 14,
        SGP: 13,
        HKG: 14,
        PHP: 17,
        KOR: 17,
        IDN: 18,
        MYS: 17,
        THA: 16,
        SAU: 17,
        ARE: 17,
        KWT: 14,
        QAT: 14,
        OMN: 14,
        IND: 16,
        EGY: 17,
        RUS: 16,
        EUP: 20,
      },
      checked: false,
      validityFunction: val.checkPhone,
    },
    cardNumber: { value: "", valid: true, checked: false, validityFunction: val.checkCardNumber },
    expYear: { value: "", valid: true, checked: false, validityFunction: val.checkYear },
    expMonth: { value: "", valid: true, checked: false, validityFunction: val.checkMonth },
    cvc: { value: "", valid: true, max: 4, checked: false, validityFunction: val.checkCvc },
    country: { value: "", valid: true, checked: false, validityFunction: val.checkCountry },
    state: { value: "", valid: true, checked: false, max: 50, validityFunction: val.checkState },
    city: { value: "", valid: true, checked: false, max: 50, validityFunction: val.checkCity },
    postalCode: { value: "", valid: true, max: 10, checked: false, validityFunction: val.checkPostalCode },
    street: { value: "", valid: true, max: 50, checked: false, validityFunction: val.checkStreet },
    no: { value: "", valid: true, max: 10, checked: false, validityFunction: val.checkNo },
    // todito_nip: { value: "", valid: true, max: 4, checked: false },
    todito_cardNo: { value: "", valid: true, max: 18, checked: false, validityFunction: val.checkToditoCardNo },
    todito_pin: { value: "", valid: true, max: 4, checked: false, validityFunction: val.checkToditoPin },
    mach_cardNo: { value: "", valid: true, max: 16, checked: false, validityFunction: val.checkMachCardNo },
    vita_password: { value: "", valid: true, max: 20, checked: false, validityFunction: val.checkVitaPassword },
    vita_id: { value: "", valid: true, max: 20, checked: false, validityFunction: val.checkVitaId },
    installments: { stage: null, amount: null, amount_each: null, rate: null },
    source: { value: "", valid: true, max: 150, checked: false, validityFunction: val.checkSource },
    boleto_cep: { value: "", valid: true, checked: false, validityFunction: val.checkCEP },
    bill_country: { value: "", valid: true, checked: false, validityFunction: val.checkBillCountry },
    bill_state: { value: "", valid: true, checked: false, max: 50, validityFunction: val.checkBillState },
    bill_city: { value: "", valid: true, checked: false, max: 50, validityFunction: val.checkBillCity },
    bill_street: { value: "", valid: true, checked: false, max: 50, validityFunction: val.checkBillStreet },
    bill_no: { value: "", valid: true, max: 10, checked: false, validityFunction: val.checkBillNo },
    bill_postalCode: { value: "", valid: true, max: 10, checked: false, validityFunction: val.checkBillPostalCode },
    isSameAddress: true,
    delivery_country: { value: "", valid: true, checked: false, validityFunction: val.checkDeliveryCountry },
    delivery_state: { value: "", valid: true, checked: false, max: 50, validityFunction: val.checkDeliveryState },
    delivery_city: { value: "", valid: true, checked: false, max: 50, validityFunction: val.checkDeliveryCity },
    delivery_street: { value: "", valid: true, checked: false, max: 50, validityFunction: val.checkDeliveryStreet },
    delivery_no: { value: "", valid: true, max: 10, checked: false, validityFunction: val.checkDeliveryNo },
    delivery_postalCode: {
      value: "",
      valid: true,
      max: 10,
      checked: false,
      validityFunction: val.checkDeliveryPostalCode,
    },
    account_number: { type: "C", value: "", valid: true, checked: false, validityFunction: val.checkAccountNumber },
    bank_list: { value: "", valid: true, checked: false, validityFunction: val.checkBankList },
    address: { value: "", valid: true, checked: false, max: 80, validityFunction: val.checkAddress },
  });

  useEffect(() => {
    const region = props.tradeInfo?.app_info?.region;
    // console.log("region status: ", region);
    const countryObj = countryList.find((item) => item?.alpha3_code === region) || {
      country_name: "United States",
      alpha2_code: "US",
      alpha3_code: "USA",
      phone_code: "+1",
    };
    setForm((prev) => {
      return {
        ...prev,
        idType: countryCode2TaxIdType[region]?.[0],
        phone: { ...prev.phone, value: countryObj.phone_code, countryObj: countryObj },
      };
    });
  }, [props.tradeInfo?.app_info?.region]);

  useEffect(() => {
    setForm((prev) => {
      return {
        ...prev,
        isGlobal: props.tradeInfo?.app_info?.region === "GLB" || props.tradeInfo?.app_info?.region === "EUP",
      };
    });
  }, [props.method, props.payChannel, props.tradeInfo?.app_info?.region]);

  const getValidityResult = ({ isSetForm = true } = {}) => {
    const formConfig = getFromConfig({
      region: props.tradeInfo?.app_info?.region,
      method: props.method,
      channel: props.payChannel,
      isAddressSame: form.isSameAddress,
    });
    let validity = false;
    if (formConfig) {
      validity = formConfig?.reduce?.((result, item) => {
        const itemValidity = !isSetForm
          ? form?.[item].valid && form?.[item].checked
          : typeof form?.[item]?.validityFunction === "function"
          ? form?.[item]?.validityFunction({
              form: form,
              setForm: setForm,
              region: props.tradeInfo?.app_info?.region,
              isGlobal: form.isGlobal,
              bank: props.channel?.data?.[0]?.bank,
            })
          : false;
        // console.log(item + " ---- " + itemValidity);
        return result && itemValidity;
      }, true);
    }

    if (props.tradeInfo?.checkout_url) {
      validity =
        (!isSetForm ? form.source.checked && form.source.valid : val.checkSource({ form, setForm })) && validity;
    }

    // console.log("validity >>>>> ", validity);
    return validity;
  };

  const scrollToFilling = () => {
    scroller.scrollTo("filling_detail", {
      duration: 500,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    });
  };

  const checkIsAllValid = () => {
    let validityResult = getValidityResult();
    if (props.method === "CreditCard" && props.payChannel === "Paypal") {
      props.setPaypalCreditCard({
        type: "keepPrev",
        key: "cardName",
        value: { checked: true },
      });
      props.setPaypalCreditCard({
        type: "keepPrev",
        key: "cardNumber",
        value: { checked: true },
      });
      props.setPaypalCreditCard({
        type: "keepPrev",
        key: "expDate",
        value: { checked: true },
      });
      props.setPaypalCreditCard({
        type: "keepPrev",
        key: "cvv",
        value: { checked: true },
      });
      let paypalValidity =
        validityResult &&
        props.paypalCreditCard.authorizationToken &&
        props.paypalCreditCard.deviceData &&
        props.paypalCreditCard.cardName.valid &&
        props.paypalCreditCard.cardName.checked &&
        props.paypalCreditCard.cardNumber.valid &&
        props.paypalCreditCard.cardNumber.checked &&
        props.paypalCreditCard.expDate.valid &&
        props.paypalCreditCard.expDate.checked &&
        props.paypalCreditCard.cvv.valid &&
        props.paypalCreditCard.cvv.checked;
      !paypalValidity && scrollToFilling();
      return paypalValidity;
    } else {
      !validityResult && scrollToFilling();
      return validityResult;
    }
  };

  const isPeru = () => {
    return props.method === "Cash" && props.payChannel === "SafetyPay" && props.tradeInfo?.app_info?.region === "PER";
  };

  const checkIsAllSelected = () => {
    if (props.method) {
      if (channelConfig(props.method, props.payChannel)) {
        if (props.channel?.data) {
          return true;
        } else {
          setSelectNotice({ state: true, notice: t("select_notice") });
          return false;
        }
      } else {
        return true;
      }
    } else {
      setSelectNotice({ state: true, notice: t("select_notice") });
      return false;
    }
  };

  const handleError = (err, notice) => {
    console.error(err);
    setIsloading(false);
    notify(t(notice || "err_100002"));
    // notify("Something went wrong! Please use another payment method.");
  };
  // console.log(new Date().getTime());
  const doPay = (reqData) => {
    setIsloading(true);
    req
      .post(`/api/trade/pay?trade_no=${props.prepayId}`, reqData)
      .then((res) => {
        Object.keys(window?.localStorage || []).forEach((item) => {
          if (item.substring(0, 3) === "PS_") {
            localStorage?.removeItem(item);
          }
        });
        localStorage?.setItem("PS_" + props.prepayId + "channel", JSON.stringify(props.channel));
        localStorage?.setItem("PS_" + props.prepayId + "method", JSON.stringify(props.method));
        if (res.data.code === "10000") {
          localStorage?.setItem("PS_" + props.prepayId + "payData", JSON.stringify(res.data.data));
          localStorage?.setItem("__frm_name", form.name.value);
          localStorage?.setItem("__frm_id_type", form.idType);
          localStorage?.setItem("__frm_tax_id", form.taxId.value);
          localStorage?.setItem("__frm_email", form.email.value);
          localStorage?.setItem("__frm_phone", form.phone.value);

          let tradeInfo = {
            ...props.tradeInfo,
            user: {
              ...props.tradeInfo?.user,
              identify: { type: form.idType, number: form.taxId.value },
              name: form.name.value,
            },
          };
          props.setTradeInfo(tradeInfo);

          if ((props.method === "PSE" || props.method === "Efecty") && props.payChannel === "Payty") {
            localStorage?.setItem("PS_prepay_id", props.prepayId);
            localStorage?.setItem("PS_return_url", props.returnUrl);
            let queryObj = res.data?.data?.form_date && jsonParse(res.data?.data?.form_date);
            let url =
              res.data?.data?.pay_url +
              "?" +
              Object.keys(queryObj)
                ?.map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(queryObj[k])}`)
                .join("&");
            url && window.location.replace(url);
          } else if (
            props.method === "Khipu" ||
            ((props.method === "PagoEfectivo" || props.method === "BankTransfer" || props.method === "Cash") &&
              props.payChannel === "PagoEfectivo") ||
            (props.method === "BankTransfer" && props.payChannel === "Multicaja") ||
            (props.method === "BankTransfer" && props.payChannel === "ETpay") ||
            (props.method === "CreditCard" && props.payChannel === "Monnet") ||
            ((props.method === "Pago46" || props.method === "CreditCard") && props.payChannel === "Payku") ||
            ((props.method === "Efecty" || props.method === "PSE") && props.payChannel === "PaytyV2") ||
            (props.method === "PSE" && props.payChannel === "PayValida") ||
            (props.method === "OpenFinance" && props.payChannel === "Iniciador") ||
            (props.method === "PSE" && props.payChannel === "ACH") ||
            (props.method === "WebPay" && props.payChannel === "Payku") ||
            (props.method === "Wallet" &&
              (props.payChannel === "Daviplata" ||
                props.payChannel === "Rappipay" ||
                props.payChannel === "Dale" ||
                props.payChannel === "Movii" ||
                props.payChannel === "Nequi" ||
                props.payChannel === "Chek"))
          ) {
            res.data?.data?.pay_url && window.location.replace(res.data?.data?.pay_url);
          } else if (
            ((props.method === "Cash" || props.method === "BankTransfer") && props.payChannel === "SafetyPay") ||
            (props.method === "SPEI" &&
              (props.payChannel === "OpenPay" ||
                props.payChannel === "STP" ||
                props.payChannel === "Banwire" ||
                props.payChannel === "OPM")) ||
            (props.method === "Cash" && (props.payChannel === "Banwire" || props.payChannel === "OpenPay")) ||
            (props.method === "OXXO" &&
              (props.payChannel === "Banwire" ||
                props.payChannel === "CardPay" ||
                props.payChannel === "Localpayment")) ||
            ((props.method === "Boleto" || props.method === "BoletoRapido") && props.payChannel === "BS2") ||
            (props.method === "PIX" &&
              (props.payChannel === "BS2" ||
                props.payChannel === "Bradesco" ||
                props.payChannel === "Rendimento" ||
                props.payChannel === "Nupay" ||
                props.payChannel === "Genial")) ||
            (props.method === "DepositExpress" && props.payChannel === "Levpay") ||
            (props.method === "OTC" && props.payChannel === "Xendit") ||
            (props.method === "Cash" && props.payChannel === "PayCash") ||
            (props.method === "OXXOPay" && props.payChannel === "Conekta") ||
            (props.method === "Baloto" && (props.payChannel === "PayValida" || props.payChannel === "IGT")) ||
            (props.method === "Wallet" &&
              (props.payChannel === "Mach" ||
                props.payChannel === "Vita" ||
                props.payChannel === "Tupay" ||
                props.payChannel === "AME")) ||
            (props.method === "CoDi" && props.payChannel === "STP") ||
            (props.method === "KLAP" && props.payChannel === "Multicaja") ||
            (props.method === "Cash" && props.payChannel === "Multicaja") ||
            (props.method === "SuRed" && props.payChannel === "PayValida") ||
            (props.method === "GANA" && props.payChannel === "PayValida") ||
            (props.method === "Bancolombia" && props.payChannel === "PayValida") ||
            (props.method === "PlayersBankPIX" && props.payChannel === "BS2") ||
            ((props.method === "BankTransfer" ||
              props.method === "PagoFacil" ||
              props.method === "Rapipago" ||
              props.method === "Cash") &&
              props.payChannel === "Localpayment") ||
            (props.method === "Transfiya" && props.payChannel === "Movii") ||
            (props.method === "BNCR" && props.payChannel === "PayValida") ||
            (props.method === "Crypto" && props.payChannel === "TripleA") ||
            (props.method === "BNCR" && props.payChannel === "PayCash")
          ) {
            props.setPayData(res.data?.data);
            props.setTradeStatus({ id: "order" });
            history.replace(`/checkout?trade_no=${props.prepayId}${returnUrlAsParam()}`);
          } else if (props.method === "Lottery") {
            props.setPayData({ ...res.data.data, userCPF: form.taxId.value });
            props.setTradeStatus({ id: "order" });
            history.replace(`/checkout?trade_no=${props.prepayId}${returnUrlAsParam()}`);
          } else if (
            (props.method === "Wallet" && props.payChannel === "AME") ||
            (props.method === "CoDi" && props.payChannel === "STP")
          ) {
            props.setPayData(res.data.data);
            props.setTradeStatus({ id: "order" });
            history.replace(`/checkout?trade_no=${props.prepayId}${returnUrlAsParam()}`);
          } else if (props.method === "Wallet" && props.payChannel === "PicPay") {
            res.data?.data?.wallet_url && window.location.replace(res.data?.data?.wallet_url);
          } else if ((props.method === "BankTransfer" || props.method === "Cash") && props.payChannel === "Monnet") {
            if (props.tradeInfo?.app_info?.region === "PER" && res.data.data?.reference) {
              props.setPayData(res.data.data);
              props.setTradeStatus({ id: "order" });
              history.replace(`/checkout?trade_no=${props.prepayId}${returnUrlAsParam()}`);
            } else {
              res.data?.data?.pay_url && window.location.replace(res.data?.data?.pay_url);
            }
          } else if (
            (props.method === "Wallet" && props.payChannel === "Todito") ||
            (props.method === "CreditCard" && props.payChannel === "other") ||
            (props.method === "CreditCard" && props.payChannel === "Paypal") ||
            (props.method === "CreditCard" && props.payChannel === "Localpayment") ||
            (props.method === "DebitCard" && props.payChannel === "Localpayment") ||
            (props.method === "Wallet" && props.payChannel === "Paypal") ||
            (props.method === "CreditCard" &&
              props.payChannel === "OpenPay" &&
              props.tradeInfo?.app_info?.region === "PER") ||
            (props.method === "CreditCard" && props.payChannel === "Movii")
          ) {
            props.setTradeStatus({ id: "process", query: true });
            history.replace(`/result?trade_no=${props.prepayId}${returnUrlAsParam()}`);
          } else if (
            props.method === "CreditCard" &&
            props.payChannel === "OpenPay" &&
            props.tradeInfo?.app_info?.region === "MEX"
          ) {
            if (window.location?.hostname === "checkout-test.luxpag.com" || window.location?.hostname === "localhost") {
              props.setTradeStatus({ id: "process", query: true });
              history.replace(`/result?trade_no=${props.prepayId}${returnUrlAsParam()}`);
            } else {
              res.data?.data?.check_url && window.location.replace(res.data?.data?.check_url);
            }
          } else if (props.method === "Wallet" && props.payChannel === "TPaga") {
            if (window.innerWidth < 800) {
              res.data?.data?.wallet_url && window.location.replace(res.data?.data?.wallet_url);
            } else {
              props.setPayData(res.data.data);
              props.setTradeStatus({ id: "order" });
              history.replace(`/checkout?trade_no=${props.prepayId}${returnUrlAsParam()}`);
            }
          } else if (props.method === "CreditCard" && props.payChannel === "CrediMax") {
            if (res.data?.data?.check_url) {
              let data = {
                ...res.data?.data,
                form_date: res.data?.data?.form_data,
                pay_url: res.data?.data?.check_url,
              };
              setSubmitData(data);
            } else {
              props.setTradeStatus({ id: "process", query: true });
              history.replace(`/result?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
            }
          }
        } else {
          // notify(t(`err_${res.data.code}`));
          localStorage?.setItem("PS_" + props.prepayId + "payData", JSON.stringify(res.data));
          props.setPayData(res.data);
          if (res.data.code === "40000") {
            // this code is not same as we get using query endpoint, so dont use tradaStatusCode function
            props.setTradeStatus({
              id: "fail",
              text: "failed", // i18n id
              notice: "system_busy", // i18n id
            });
          } else if (res.data.code === "90003") {
            // this code is not same as we get using query endpoint, so dont use tradaStatusCode function
            props.setTradeStatus({
              id: "process",
              text: "risk_control", // i18n id
              notice: "", // i18n id
            });
          } else {
            props.setTradeStatus({ id: "fail" });
          }
          history.replace(`/result?trade_no=${props.prepayId}${returnUrlAsParam()}`);
        }
      })
      .catch((err) => {
        if (err && !err.response) {
          handleError(err, "network_err");
        } else {
          handleError(err);
        }
      });
  };

  const isReadyToPay = () => {
    return checkIsAllSelected() && checkIsAllValid() && isChecked;
  };

  const payRequest = (data) => {
    if (isReadyToPay()) {
      setIsloading(true);
      const commonData = {
        method: props.method,
        username: form.name.value,
        email: form.email.value.toLowerCase(),
        phone: form.phone.value.replace(/[^\d]/g, ""),
        amount: props.paymentInfo?.pay_amount,
        currency: props.paymentInfo?.pay_currency,
        out_trade_no: props.tradeInfo?.out_trade_no,
        checkout_url: form.source.value,
        prev_url: document.referrer,
        pay_region: props.tradeInfo?.app_info?.region,
      };
      let taxIdData = {
        id: {
          type: form.idType,
          number: form.taxId.value.replace(/[-/.]/g, ""),
        },
      };

      if (props.tradeInfo?.checkout_url) {
        commonData.original_source = form.source.value;
      }
      if (
        (props.method === "CreditCard" &&
          (props.payChannel === "Paypal" ||
            props.payChannel === "OpenPay" ||
            props.payChannel === "CardPay" ||
            props.payChannel === "E-Comprocessing" ||
            props.payChannel === "CrediMax" ||
            props.payChannel === "Xendit" ||
            props.payChannel === "Movii" ||
            props.payChannel === "Izipay")) ||
        (props.method === "RecurringCreditCard" && props.payChannel === "Paypal")
      ) {
        const billingAddress = {
          address: {
            zip_code: form.bill_postalCode.value,
            country: form.bill_country.value,
            state: form.bill_state.value,
            city: form.bill_city.value,
            street: form.bill_street.value,
            street_number: form.bill_no.value,
          },
        };
        commonData.billing = { ...billingAddress };
        commonData.shipping = form.isSameAddress
          ? { ...billingAddress }
          : {
              address: {
                zip_code: form.delivery_postalCode.value,
                country: form.delivery_country.value,
                state: form.delivery_state.value,
                city: form.delivery_city.value,
                street: form.delivery_street.value,
                street_number: form.delivery_no.value,
              },
            };
      }
      // 不通的支付方式 支付渠道判断
      let reqData = {};
      if (props.method === "Boleto" || props.method === "Lottery") {
        reqData = {
          ...commonData,
          ...taxIdData,
          address: {
            zip_code: form.boleto_cep.value,
          },
          zip_code: form.boleto_cep.value,
        };
        doPay(reqData);
      } else if (props.method === "Cash" && props.payChannel === "OpenPay") {
        reqData = {
          ...commonData,
          address: {
            zip_code: form.postalCode.value,
            state: form.state.value,
            city: form.city.value,
            street_number: form.street.value + " " + form.no.value,
          },
        };
        doPay(reqData);
      } else if (props.method === "CreditCard" && props.payChannel === "Paypal") {
        let childRef = window.innerWidth <= 800 ? paypalChildRefMobile : paypalChildRef;
        setIsloading(true);
        childRef.current.getToken(
          (payload) => {
            //console.log("Success", payload);
            let reqData = {
              ...commonData,
              ...taxIdData,
              username: payload.details.cardholderName,
              installments: form.installments.stage,
              issuer: payload.details.cardType,
              token: payload.nonce,
              card_no: payload.details.bin + "******" + payload.details.lastFour,
              device_data: props.paypalCreditCard.deviceData,
              fingerprint: window.black_box_tongdun,
              forter_token_cookie: window.black_box_forter,
            };
            doPay(reqData);
          },
          (err) => {
            console.log("fail", err);
            handleError(err);
          }
        );
      } else if (props.method === "Wallet" && props.payChannel === "Paypal") {
        let reqData = {
          ...commonData,
          channel: props.payChannel,
          ...taxIdData,
          token: data.nonce,
          device_data: data.deviceData,
        };
        doPay(reqData);
      } else if (props.method === "DepositExpress") {
        reqData = {
          ...commonData,
          ...taxIdData,
          bank: props.channel?.data[0]?.bank,
        };
        doPay(reqData);
      } else if (
        props.method === "PIX" ||
        (props.method === "Efecty" && props.payChannel === "Payty") ||
        (props.method === "PSE" && (props.payChannel === "PayValida" || props.payChannel === "Payty")) ||
        ((props.method === "Efecty" || props.method === "PSE") && props.payChannel === "PaytyV2") ||
        ((props.method === "PagoEfectivo" || props.method === "BankTransfer" || props.method === "Cash") &&
          props.payChannel === "PagoEfectivo") ||
        (props.method === "KLAP" && props.payChannel === "Multicaja") ||
        (props.method === "Cash" && props.payChannel === "Multicaja") ||
        (props.method === "BankTransfer" && props.payChannel === "Multicaja") ||
        (props.method === "CreditCard" && props.payChannel === "Monnet") ||
        (props.method === "SuRed" && props.payChannel === "PayValida") ||
        (props.method === "GANA" && props.payChannel === "PayValida") ||
        (props.method === "CreditCard" && props.payChannel === "Payku") ||
        (props.method === "PagoFacil" && props.payChannel === "Localpayment") ||
        (props.method === "Rapipago" && props.payChannel === "Localpayment") ||
        ((props.method === "BankTransfer" || props.method === "Cash") && props.payChannel === "Monnet") ||
        (props.method === "Baloto" && (props.payChannel === "PayValida" || props.payChannel === "IGT")) ||
        (props.method === "WebPay" && props.payChannel === "Payku") ||
        (props.method === "Bancolombia" && props.payChannel === "PayValida")
      ) {
        reqData = {
          ...commonData,
          ...taxIdData,
        };
        doPay(reqData);
      } else if (
        // wallet must pass the channel!!!!!
        props.method === "Wallet" &&
        (props.payChannel === "Vita" ||
          props.payChannel === "Mach" ||
          props.payChannel === "Chek" ||
          props.payChannel === "AME" ||
          props.payChannel === "PicPay" ||
          props.payChannel === "TPaga")
      ) {
        reqData = {
          channel: props.payChannel,
          ...commonData,
          ...taxIdData,
        };
        doPay(reqData);
      } else if (props.method === "Wallet" && props.payChannel === "Todito") {
        reqData = {
          channel: props.payChannel,
          ...commonData,
          ...taxIdData,
          card_no: form.todito_cardNo.value.replace(/\s/g, ""),
          security_code: form.todito_pin.value.replace(/\s/g, ""),
        };
        doPay(reqData);
      } else if (props.method === "Khipu") {
        reqData = {
          ...commonData,
          bank: props.channel?.data[0]?.bank_id,
        };
        doPay(reqData);
      } else if ((props.method === "Cash" || props.method === "BankTransfer") && props.payChannel === "SafetyPay") {
        reqData = {
          ...commonData,
          language_code: props.channel?.data[0]?.language_code,
          bank: props.channel?.data[0]?.bank_id,
          payment_type: props.method === "BankTransfer" ? "1" : "2",
        };
        if (props.method === "BankTransfer") {
          reqData = { ...reqData, ...taxIdData };
        }
        doPay(reqData);
      } else if (
        props.method === "Wallet" &&
        (props.payChannel === "Daviplata" ||
          props.payChannel === "Rappipay" ||
          props.payChannel === "Dale" ||
          props.payChannel === "Movii" ||
          props.payChannel === "Nequi")
      ) {
        let reqData = {
          ...commonData,
          channel: props.payChannel,
          ...taxIdData,
          address: {
            street: form.address.value,
          },
        };
        doPay(reqData);
      } else if (props.method === "Wallet" && props.payChannel === "Tupay") {
        let reqData = {
          ...commonData,
          channel: props.payChannel,
          username: form.firstName.value.trim() + " " + form.lastName.value.trim(),
          ...taxIdData,
        };
        doPay(reqData);
      } else if (props.method === "CreditCard" && props.payChannel === "OpenPay") {
        const region = props?.tradeInfo?.app_info?.region;
        setIsloading(true);
        if (props.loadedLibs["CreditCard-OpenPay"]) {
          setOpenpayAcoount(region);
          const deviceSessionId = window.OpenPay?.deviceData?.setup("payment-form", "deviceIdHiddenFieldName");

          tokenize(
            props.tradeInfo?.app_info?.app_id,
            issuer,
            form,
            (token) => {
              // callback for success
              let reqData = {
                ...commonData,
                extra_token: deviceSessionId,
                token: token,
                card_no: hidedCardNo(form.cardNumber.value),
                fingerprint: window.black_box_tongdun,
                forter_token_cookie: window.black_box_forter,
              };
              doPay(reqData);
            },
            (err) => {
              // callback for fail
              handleError(err);
            }
          );
        } else {
          handleError("OpenPay libs are not loaded successfully :(");
        }
      } else if (props.method === "BankTransfer" && props.payChannel === "Localpayment") {
        let reqData = {
          ...commonData,
          ...taxIdData,
          bank: form.bank_list.value?.bank_id,
          account_type: form.account_number.type,
          account_id: form.account_number.value,
        };
        doPay(reqData);
      } else if (props.method === "PSE" && props.payChannel === "ACH") {
        let reqData = {
          ...commonData,
          ...taxIdData,
          bank: form.bank_list.value?.data?.[0]?.bank_id,
          bank_name: form.bank_list.value?.data?.[0]?.bank_name,
          address: {
            street: form.address.value,
          },
        };
        doPay(reqData);
      } else if (props.method === "Cash" && props.payChannel === "Localpayment") {
        let reqData = {
          ...commonData,
          ...taxIdData,
          bank: form.bank_list.value?.bank_id,
          bank_name: form.bank_list.value?.bank_name,
        };
        doPay(reqData);
      } else if (props.method === "Crypto" && props.payChannel === "TripleA") {
        let reqData = {
          ...commonData,
          crypto_currency: props.channel?.data[0]?.channel,
        };
        doPay(reqData);
      } else if (props.method === "OpenFinance" && props.payChannel === "Iniciador") {
        let reqData = {
          ...commonData,
          ...taxIdData,
          bank: form.bank_list.value?.bank_id,
        };
        doPay(reqData);
      } else if (props.method === "CreditCard" && props.payChannel === "Movii") {
        if (props.loadedLibs[props.method + "-" + props.payChannel]) {
          setIsloading(true);
          tokenize(
            props.tradeInfo?.app_info?.app_id,
            issuer,
            form,
            (token) => {
              // callback for success
              let reqData = {
                ...commonData,
                card_no: hidedCardNo(form.cardNumber.value),
                issuer: issuer,
                token: token,
                fingerprint: window.black_box_tongdun,
                forter_token_cookie: window.black_box_forter,
              };
              doPay(reqData);
            },
            (err) => {
              // callback for fail
              handleError(err);
            }
          );
        } else {
          handleError(`${props.payChannel} libs are not loaded successfully :(`);
        }
      } else if (
        (props.method === "CreditCard" && props.payChannel === "Localpayment") ||
        (props.method === "DebitCard" && props.payChannel === "Localpayment")
      ) {
        if (props.loadedLibs["CreditCard-Localpayment"] || props.loadedLibs["DebitCard-Localpayment"]) {
          setIsloading(true);
          tokenize(
            props.tradeInfo?.app_info?.app_id,
            issuer,
            form,
            (token) => {
              // callback for success
              let reqData = {
                ...commonData,
                card_no: hidedCardNo(form.cardNumber.value),
                issuer: issuer,
                token: token,
                fingerprint: window.black_box_tongdun,
                forter_token_cookie: window.black_box_forter,
                ...taxIdData,
              };
              doPay(reqData);
            },
            (err) => {
              // callback for fail
              handleError(err);
            }
          ).catch((err) => {
            handleError(`Google captcha error: ${err}`);
          });
        } else {
          handleError(`${props.payChannel} libs are not loaded successfully :(`);
        }
      } else if (props.method === "CreditCard" && props.payChannel === "CrediMax") {
        if (props.loadedLibs[props.method + "-" + props.payChannel]) {
          setIsloading(true);
          let reqData = {
            ...commonData,
            username: data.name,
            card_no: data?.cardNumber?.replace(/[xX]/g, "*"),
            region: country2codes.find((item) => item.country_name === form.bill_country.value),
            token: data?.sessionId,
            fingerprint: window.black_box_tongdun,
            forter_token_cookie: window.black_box_forter,
            device_data: JSON.stringify({
              accept_header: "application/json",
              java_enabled: "true",
              language: String(window.navigator.language),
              color_depth: [1, 4, 8, 15, 16, 24, 32, 48].includes(window.screen.colorDepth)
                ? String(window.screen.colorDepth)
                : String(
                    [1, 4, 8, 15, 16, 24, 32, 48].reduce((result, num) => {
                      return window.screen.colorDepth - num > 0 &&
                        window.screen.colorDepth - num < window.screen.colorDepth - result
                        ? num
                        : result;
                    }, 1)
                  ),
              screen_height: String(window.screen.height),
              screen_width: String(window.screen.width),
              challenge_window_size: "full_screen",
              time_zone_offset: String(new Date().getTimezoneOffset()),
              user_agent: window.navigator.userAgent,
            }),
          };
          doPay(reqData);
        } else {
          handleError(`${props.payChannel} libs are not loaded successfully :(`);
        }
      } else {
        doPay(commonData);
      }
    }
  };
  return (
    <>
      {showPolicy && <PrivacyPolicy back={closePolicy} />}
      <div className="home__loader" style={{ display: isLoading ? "block" : "none" }}>
        <div className="loader">
          <PuffLoader color="#01b88f" />
        </div>
      </div>
      <div className="home__notice" style={{ display: selectNotice.state ? "block" : "none" }}>
        <div className="home__notice__warning">
          <img
            className="home__notice__warningClose"
            src={Close}
            alt=""
            onClick={() => {
              setSelectNotice({ state: false, notice: "" });
            }}
          />
          <img src={Warning} alt="" />
          <p className="home__notice__warningText">{selectNotice.notice || ""}</p>
          <button
            className="home__notice__warningButton"
            onClick={() => {
              typeof selectNotice.callBack === "function" && selectNotice.callBack();
              setSelectNotice({ state: false, notice: "" });
            }}
          >
            {t("okay")}
          </button>
        </div>
      </div>

      <div className="home">
        <div className="home__container">
          <div className="home__left">
            <div className="home__insert">
              <span className="home__steps">
                <img src={props.method ? Step1Act : Step1Pas} alt="" />
              </span>
              <p className="home__insertTitle">{t("payment_method")}</p>
              <div className="home__insertContainer" style={{ paddingTop: "20px" }}>
                {formatMethod(props.tradeInfo?.channel_info_list)?.map((item) => {
                  return methodConfig[item?.method] !== undefined ? (
                    <PaymentMethod
                      image={methodConfig[item?.method]?.info?.image}
                      text={methodConfig[item?.method]?.info?.text}
                      type={item?.method}
                      channel={item?.channel}
                      loading={setIsloading}
                      key={item?.method}
                      handleError={handleError}
                    />
                  ) : (
                    ""
                  );
                })}
              </div>
            </div>

            {channelConfig(props.method, props.payChannel) && (
              <div className="home__insert">
                <span className="home__steps">
                  <img src={props.channel ? Step2Act : Step2Pas} alt="" />
                </span>
                <p className="home__insertTitle">{t("bank_agent")}</p>
                <div className="home__insertContainer">
                  {!isPeru() &&
                    props.channelList[props.method]?.map((item) => {
                      return <Channel data={item} key={item.key} loading={setIsloading} handleError={handleError} />;
                    })}

                  {isPeru() && (
                    <>
                      <ChannelSelect channelList={props.channelList} method={props.method} handleError={handleError} />
                    </>
                  )}
                </div>
                {/* promotions and notices */}
                {props.method === "Cash" &&
                  props.payChannel === "PayCash" &&
                  props.tradeInfo?.app_info?.region === "ECU" && (
                    <p className="home__channelPromotion__notice">
                      {t("pay_points1")}
                      <a
                        href="https://checkout.pagsmile.com/public/pagsmile-files/puntos-Ecuador.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("pay_points2")}
                      </a>
                      {t("pay_points3")}
                    </p>
                  )}
              </div>
            )}
            <div className="home__insert" style={{ borderLeft: "none", paddingBottom: "100px" }}>
              <span className="home__steps">
                <img src={getValidityResult({ isSetForm: false }) ? Step3Act : Step3Pas} alt="" />
              </span>
              <p className="home__insertTitle">{t("filling_details")}</p>
              <div className="home--column">
                {(((props.method === "Boleto" || props.method === "BoletoRapido") && props.payChannel === "BS2") ||
                  (props.method === "Lottery" && props.payChannel === "Caixa")) && (
                  <>
                    <div className="home--row">
                      <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <div className="home--row">
                      <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <Cep form={form} setForm={setForm} />
                    </div>
                    <div className="home--row">
                      <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                  </>
                )}
                {props.method === "CreditCard" &&
                  props?.tradeInfo?.app_info?.region === "BRA" &&
                  props.paypalCreditCard.authorizationToken && (
                    <>
                      <PaypalCreditcard
                        authorizationToken={props.paypalCreditCard.authorizationToken}
                        loading={setIsloading}
                        handleError={handleError}
                        ref={paypalChildRef}
                        target="pc"
                      />
                      <div className="home--row">
                        <Installment form={form} setForm={setForm} />
                        <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <Address form={form} setForm={setForm} setSelectNotice={setSelectNotice} />
                    </>
                  )}

                {props.method === "CreditCard" && props.payChannel === "OpenPay" && (
                  <>
                    <div className="home--row">
                      <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} creditcard={true} />
                      {/* <Installment form={form} setForm={setForm} /> */}
                    </div>
                    <div className="home--row">
                      <CreditCardNumber form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <CreditCardExpTime form={form} setForm={setForm} />
                    </div>

                    <div className="home--row">
                      <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <Address form={form} setForm={setForm} setSelectNotice={setSelectNotice} />
                    <HiddenForm form={form} />
                  </>
                )}

                {props.method === "Crypto" && props.payChannel === "TripleA" && (
                  <>
                    <div className="home--row">
                      <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                  </>
                )}

                {props.method === "CreditCard" && props.payChannel === "Movii" && (
                  <>
                    <div className="home--row">
                      <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} creditcard={true} />
                    </div>
                    <div className="home--row">
                      <CreditCardNumber form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <CreditCardExpTime form={form} setForm={setForm} />
                    </div>

                    <div className="home--row">
                      <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <Address form={form} setForm={setForm} />
                  </>
                )}

                {((props.method === "CreditCard" && props.payChannel === "Localpayment") ||
                  (props.method === "DebitCard" && props.payChannel === "Localpayment")) && (
                  <>
                    <div style={{ marginBottom: "15px", display: "flex", alignItems: "center" }}>
                      <img src={VisaCard} alt="" />
                      <img src={MasterCard} alt="" />
                      {props.method === "CreditCard" && props.payChannel === "Localpayment" && (
                        <img src={Amex} alt="" />
                      )}
                    </div>
                    <div className="home--row">
                      <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} creditcard={true} />
                      <CreditCardNumber form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <div className="home--row">
                      <CreditCardExpTime form={form} setForm={setForm} />
                      <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>

                    <div className="home--row">
                      <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <Address form={form} setForm={setForm} setSelectNotice={setSelectNotice} />
                  </>
                )}

                {((props.method === "BankTransfer" && props.payChannel === "SafetyPay") ||
                  (props.method === "Efecty" && props.payChannel === "Payty") ||
                  (props.method === "PSE" && (props.payChannel === "PayValida" || props.payChannel === "Payty")) ||
                  (props.method === "Wallet" && props.payChannel === "Vita") ||
                  (props.method === "PIX" &&
                    (props.payChannel === "BS2" ||
                      props.payChannel === "Bradesco" ||
                      props.payChannel === "Rendimento")) ||
                  (props.method === "Wallet" &&
                    (props.payChannel === "PicPay" ||
                      props.payChannel === "Paypal" ||
                      props.payChannel === "AME" ||
                      props.payChannel === "TPaga" ||
                      props.payChannel === "MOL" ||
                      props.payChannel === "Chek" ||
                      props.payChannel === "Mach" ||
                      props.payChannel === "Vita")) ||
                  (props.method === "DepositExpress" && props.payChannel === "Levpay") ||
                  ((props.method === "PagoEfectivo" || props.method === "BankTransfer" || props.method === "Cash") &&
                    props.payChannel === "PagoEfectivo") ||
                  (props.method === "KLAP" && props.payChannel === "Multicaja") ||
                  (props.method === "Cash" && props.payChannel === "Multicaja") ||
                  (props.method === "BankTransfer" && props.payChannel === "Multicaja") ||
                  (props.method === "CreditCard" && props.payChannel === "Monnet") ||
                  (props.method === "Baloto" && (props.payChannel === "PayValida" || props.payChannel === "IGT")) ||
                  (props.method === "CreditCard" && props.payChannel === "Payku") ||
                  (props.method === "SuRed" && props.payChannel === "PayValida") ||
                  (props.method === "GANA" && props.payChannel === "PayValida") ||
                  (props.method === "Bancolombia" && props.payChannel === "PayValida") ||
                  (props.method === "PlayersBank" && props.payChannel === "BS2") ||
                  (props.method === "PagoFacil" && props.payChannel === "Localpayment") ||
                  (props.method === "Rapipago" && props.payChannel === "Localpayment") ||
                  ((props.method === "Efecty" || props.method === "PSE") && props.payChannel === "PaytyV2") ||
                  (props.method === "WebPay" && props.payChannel === "Payku") ||
                  ((props.method === "BankTransfer" || props.method === "Cash") && props.payChannel === "Monnet")) && (
                  <>
                    <div className="home--row">
                      <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <div className="home--row">
                      <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                  </>
                )}

                {props.method === "Pago46" && props.payChannel === "Payku" && (
                  <>
                    <div className="home--row">
                      <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                  </>
                )}

                {((props.method === "Khipu" && props.payChannel === "Khipu") ||
                  (props.method === "BankTransfer" && props.payChannel === "ETpay")) && (
                  <>
                    <div className="home--row">
                      <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                  </>
                )}

                {props.method === "PSE" && props.payChannel === "ACH" && (
                  <>
                    <div className="home--row">
                      <BankListPopUp form={form} setForm={setForm} />
                      <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <div className="home--row">
                      <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <div className="home--row">
                      <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <AddressBox form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                  </>
                )}

                {props.loadedLibs?.["CreditCard-CrediMax"] && window.innerWidth >= 800 && (
                  <Credimax
                    style={{
                      display: props.method === "CreditCard" && props.payChannel === "CrediMax" ? "block" : "none",
                    }}
                    ref={crediMaxChildRef}
                    payRequest={payRequest}
                    setIsLoading={setIsloading}
                    form={form}
                    setForm={setForm}
                    isReadyToPay={isReadyToPay}
                    handleError={handleError}
                  />
                )}
                {props.method === "CreditCard" &&
                  props.payChannel === "CrediMax" &&
                  !props.loadedLibs?.["CreditCard-CrediMax"] && (
                    <div style={{ textAlign: "center", paddingBottom: "15px" }}>
                      <CircularProgress size={24} style={{ color: "var(--pagsmile--color)" }} />
                    </div>
                  )}

                {props.method === "CreditCard" && props.payChannel === "CrediMax" && window.innerWidth >= 800 && (
                  <>
                    <div className="home--row">
                      <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <Address form={form} setForm={setForm} />
                  </>
                )}

                {props.method === "BankTransfer" && props.payChannel === "Localpayment" && (
                  <>
                    <div className="home--row">
                      <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <BankList form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <div className="home--row">
                      <AccountNumber form={form} setForm={setForm} region={props.tradeInfo?.app_info?.region} />
                      <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <div className="home--row">
                      <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                  </>
                )}
                {props.method === "Cash" && props.payChannel === "Localpayment" && (
                  <>
                    <div className="home--row">
                      <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <div className="home--row">
                      <BankList form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <div className="home--row">
                      <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                  </>
                )}
                {props.method === "OpenFinance" && props.payChannel === "Iniciador" && (
                  <>
                    <div className="home--row">
                      <BankList form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <div className="home--row">
                      <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                  </>
                )}
                {((props.method === "Wallet" &&
                  (props.payChannel === "AlipayCN" ||
                    props.payChannel === "AlipayHK" ||
                    props.payChannel === "GCash" ||
                    props.payChannel === "KakaoPayAlipay" ||
                    props.payChannel === "KakaoPay" ||
                    props.payChannel === "TOSS" ||
                    props.payChannel === "PAYCO" ||
                    props.payChannel === "SamsungPay" ||
                    props.payChannel === "DANA" ||
                    props.payChannel === "TNG" ||
                    props.payChannel === "BOOST" ||
                    props.payChannel === "RabbitLinePay" ||
                    props.payChannel === "BPI" ||
                    props.payChannel === "TrueMoney" ||
                    props.payChannel === "GcashXendit" ||
                    props.payChannel === "Paymaya" ||
                    props.payChannel === "PayNow" ||
                    props.payChannel === "GrabPay" ||
                    props.payChannel === "MADA" ||
                    props.payChannel === "KNet" ||
                    props.payChannel === "YooMoney" ||
                    props.payChannel === "QIWI" ||
                    props.payChannel === "Fawry")) ||
                  ((props.method === "GCash" ||
                    props.method === "CreditCard" ||
                    props.method === "NetBanking" ||
                    props.method === "UPI") &&
                    props.payChannel === "SHAREitPay") ||
                  ((props.method === "AlfaClick" ||
                    props.method === "Qiwi" ||
                    props.method === "WebMoney" ||
                    props.method === "YandexMoney" ||
                    props.method === "UnionPay") &&
                    props.payChannel === "Payssion") ||
                  (props.method === "PiPay" && props.payChannel === "PiPay") ||
                  (props.method === "AlipayPlus" && props.payChannel === "AlipayPlus") ||
                  ((props.method === "CreditCard" ||
                    props.method === "BankTransfer" ||
                    props.method === "VirtualAccount") &&
                    props.payChannel === "Payletter") ||
                  (props.method === "CreditCard" && props.payChannel === "QIWI") ||
                  (props.method === "OXXO" &&
                    (props.payChannel === "Banwire" ||
                      props.payChannel === "CardPay" ||
                      props.payChannel === "Localpayment")) ||
                  (props.method === "OXXOPay" && props.payChannel === "Conekta") ||
                  (props.method === "CoDi" && props.payChannel === "STP") ||
                  (props.method === "Cash" && props.payChannel === "PayCash") ||
                  (props.method === "SPEI" &&
                    (props.payChannel === "OpenPay" ||
                      props.payChannel === "STP" ||
                      props.payChannel === "Banwire" ||
                      props.payChannel === "OPM")) ||
                  (props.method === "Cash" && props.payChannel === "SafetyPay") ||
                  (props.method === "BNCR" && props.payChannel === "PayValida") ||
                  (props.method === "BNCR" && props.payChannel === "PayCash")) && (
                  <>
                    <div className="home--row">
                      <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <div className="home--row">
                      <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                  </>
                )}
                {props.method === "Wallet" && props.payChannel === "Tupay" && (
                  <>
                    <div className="home--row">
                      <FirstName form={form} setForm={setForm} />
                      <LastName form={form} setForm={setForm} />
                    </div>
                    <div className="home--row">
                      <TaxId form={form} setForm={setForm} />
                      <Email form={form} setForm={setForm} />
                    </div>
                    <div className="home--row">
                      <MobilePhone form={form} setForm={setForm} />
                    </div>
                  </>
                )}
                {props.method === "Wallet" && props.payChannel === "Todito" && (
                  <>
                    <div className="home--row">
                      <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <div className="home--row">
                      <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <p className="home--fillTitle">{t("todito_notice")}</p>
                    <div className="home--row">
                      <CardNo form={form} setForm={setForm} />
                      <PinNo form={form} setForm={setForm} />
                    </div>
                  </>
                )}
                {props.method === "Cash" && (props.payChannel === "Banwire" || props.payChannel === "OpenPay") && (
                  <>
                    <div className="home--row">
                      <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <div className="home--row">
                      <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <div className="home--row">
                      <State form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      <City form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                    <div className="home--row">
                      <PostalCode
                        form={form}
                        setForm={setForm}
                        tradeInfo={props.tradeInfo}
                        setSelectNotice={setSelectNotice}
                      />
                      <StreetAndNumber form={form} setForm={setForm} />
                    </div>
                  </>
                )}
                {props.method === "Wallet" &&
                  (props.payChannel === "Daviplata" ||
                    props.payChannel === "Rappipay" ||
                    props.payChannel === "Dale" ||
                    props.payChannel === "Movii" ||
                    props.payChannel === "Nequi") && (
                    <>
                      <div className="home--row">
                        <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <AddressBox form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                    </>
                  )}
                {props.tradeInfo?.checkout_url && props.method && props.payChannel && (
                  <Source form={form} setForm={setForm} />
                )}
                {submitData && <SubmitForm data={submitData} />}
              </div>
            </div>
          </div>
          <div className="home__right">
            <div className="home__time">
              <TimeRemaining
                image={TimeIcon}
                time={props?.tradeInfo?.create_time}
                countdown={props?.tradeInfo?.timeout_time}
                disable={disablePay}
              />
            </div>
            <div className="home__payDetail">
              <TitleWithDetails show={true} />
              <hr />
              <div className="home__payDetail--container">
                <p className="home__payDetail--text">{t("order_value")}</p>
                <p className="home__payDetail--value">
                  <FormatMoney type="order" />
                </p>
              </div>
              <div
                className="home__payDetail--container"
                style={{
                  display:
                    props?.paymentInfo?.fees?.additional_sum || props?.paymentInfo?.fees?.processing_sum
                      ? "flex"
                      : "none",
                }}
              >
                <p className="home__payDetail--text">{t("bank_fee")}</p>
                <p className="home__payDetail--value">
                  <FormatMoney type="bank-fee" />
                </p>
              </div>
              <div
                className="home__payDetail--container"
                style={{
                  display: props?.paymentInfo?.fees?.discountable_sum ? "flex" : "none",
                }}
              >
                <p className="home__payDetail--text">{t("discount")}</p>
                <p className="home__payDetail--value">
                  -
                  <FormatMoney type="discount" />
                </p>
              </div>
              <hr />
              <div className="home__payDetail--container" style={{ marginTop: "50px" }}>
                <p className="home__payDetail__totalText">{t("total")}</p>
                <p className="home__payDetail__totalValue">
                  <FormatMoney type="total" />
                </p>
              </div>
              {!(props.method === "Wallet" && props.payChannel === "Paypal" && props.paypalWallet.isReady) && (
                <button
                  className="home__payDetail__button"
                  onClick={() => {
                    if (props.method === "CreditCard" && props.payChannel === "CrediMax") {
                      crediMaxChildRef.current.pay();
                    } else {
                      checkIsAllSelected() && payRequest();
                    }
                  }}
                  disabled={isDiabled}
                >
                  {t("confirm")}
                </button>
              )}
              {((props.method === "Wallet" && props.payChannel === "Paypal" && props.paypalWallet.authorizationToken) ||
                props.paypalWallet.isReady) &&
                window.innerWidth >= 800 && (
                  <PaypalButton
                    setIsloading={setIsloading}
                    handleError={handleError}
                    setForm={setForm}
                    containerId="paypal-button-container-pc"
                    payRequest={payRequest}
                  />
                )}

              <div className="home__payDetail__checkboxContainer">
                <Checkbox
                  size="small"
                  checked={isChecked}
                  onChange={(event) => {
                    setIsChecked(event.target.checked);
                  }}
                />
                <span className="home__payDetail__privacyText1">
                  {t("privacy_policy1")}
                  <span
                    className="home__payDetail__privacyText2"
                    onClick={() => {
                      setShowPolicy(true);
                    }}
                  >
                    {t("privacy_policy2")}
                  </span>{" "}
                  {t("privacy_policy3")}
                </span>
              </div>
              <p className="home--error" style={{ display: isChecked ? "none" : "block" }}>
                {t("privacy_policy_error")}
              </p>
              {props.returnUrl && (
                <div className="home__payDetail__return">
                  <a href={props.returnUrl}>{t("back_shop")}</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* here starts mobile */}
      {/* here starts mobile */}
      {/* here starts mobile */}
      <div
        className="homeMobile"
        style={focusOnInput ? { paddingBottom: "0px" } : {}}
        onClick={() => {
          setFocusOnInput(false);
          // console.log("clicked");
        }}
      >
        <div className="homeMobile__channel">
          <div
            className="homeMobile__channel__top"
            onClick={() => {
              setDropDownState((prev) => !prev);
            }}
          >
            <p className="homeMobile__channelName">{props?.tradeInfo?.app_info?.app_name}</p>
            <div className="homeMobile__channelRight">
              <img
                className="homeMobile__channelRight__icon"
                src={props?.tradeInfo?.app_info?.icon_url ? props?.tradeInfo?.app_info?.icon_url : PagsmileIcon}
                alt=""
              />
              <img
                className={classNames("homeMobile__channelRight__arrow", {
                  arrow__0: !dropDownState,
                  arrow__90: dropDownState,
                })}
                src={RightArrowBlack}
                alt=""
              />
            </div>
          </div>
          {dropDownState && (
            <div className="homeMobile__channel__bottom">
              <div className="home__payDetail--container">
                <p className="home__payDetail--text homeMobile__channel__bottom__trxIdText">{t("trx_id")}</p>
                <EllipsisScroll
                  className={"home__payDetail--value homeMobile__channel__bottom__trxIdValue"}
                  text={props?.tradeInfo?.out_trade_no}
                />
              </div>
              <div className="home__payDetail--container">
                <p className="home__payDetail--text">{t("product_description")}</p>
                <EllipsisScroll className={"home__payDetail--value"} text={props?.tradeInfo?.content} />
              </div>
            </div>
          )}
        </div>

        <div className="homeMobile__slection">
          <p className="homeMobile__paymentMethod">{t("payment_method")}</p>
          {formatMethod(props.tradeInfo?.channel_info_list)?.map((item) => {
            return methodConfig[item?.method] !== undefined ? (
              <PaymentMethodMobile
                image={methodConfig[item?.method]?.info?.image}
                imageMobile={methodConfig[item?.method]?.info?.imageMobile}
                text={methodConfig[item?.method]?.info?.text}
                type={item?.method}
                channel={item?.channel}
                loading={setIsloading}
                form={form}
                setForm={setForm}
                setFocusOnInput={setFocusOnInput}
                paypalChildRefMobile={paypalChildRefMobile}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                setShowPolicy={setShowPolicy}
                key={item?.method}
                handleError={handleError}
                setSelectNotice={setSelectNotice}
                submitData={submitData}
                crediMaxChildRef={crediMaxChildRef}
                payRequest={payRequest}
                isReadyToPay={isReadyToPay}
              />
            ) : (
              ""
            );
          })}
        </div>

        <div
          className="homeMobile__footer"
          style={
            focusOnInput
              ? {
                  position: "static",
                  marginTop: "10px",
                }
              : {}
          }
        >
          <div
            className="homeMobile__footer__sort"
            onClick={() => {
              setFooterDropDownState((prev) => !prev);
            }}
          >
            <img
              className={classNames({
                arrow__180: !footerDropDownState,
                arrow__0: footerDropDownState,
              })}
              src={SortDown}
              alt=""
            />
          </div>

          <div style={{ display: footerDropDownState ? "block" : "none" }}>
            <div className="homeMobile__footer__time">
              <TimeRemaining
                image={TimeIcon}
                time={props?.tradeInfo?.create_time}
                countdown={props?.tradeInfo?.timeout_time}
                disable={disablePay}
              />
            </div>
            <div className="homeMobile__footer__order">
              <p> {t("order_value")}</p>
              <p>
                <FormatMoney type="order" />
              </p>
            </div>
            <div
              className="homeMobile__footer__order"
              style={{
                display:
                  props?.paymentInfo?.fees?.additional_sum || props?.paymentInfo?.fees?.processing_sum
                    ? "flex"
                    : "none",
              }}
            >
              <p> {t("bank_fee")}</p>
              <p>
                <FormatMoney type="bank-fee" />
              </p>
            </div>
            <div
              className="homeMobile__footer__order"
              style={{
                display: props?.paymentInfo?.fees?.discountable_sum ? "flex" : "none",
              }}
            >
              <p> {t("discount")}</p>
              <p>
                -
                <FormatMoney type="discount" />
              </p>
            </div>
          </div>

          <div className="homeMobile__footer__order">
            <p className="homeMobile__footer__totalText"> {t("total")}</p>
            <p className="homeMobile__footer__totalValue">
              <FormatMoney type="total" />
            </p>
          </div>

          {!(
            props.method === "Wallet" &&
            props.payChannel === "Paypal" &&
            props.paypalWallet.authorizationToken &&
            props.paypalWallet.isReady
          ) && (
            <button
              className="homeMobile__footer__payButton"
              onClick={() => {
                if (props.method === "CreditCard" && props.payChannel === "CrediMax") {
                  crediMaxChildRef.current.pay();
                } else {
                  checkIsAllSelected() && payRequest();
                }
              }}
              disabled={isDiabled}
            >
              {t("confirm")}
            </button>
          )}
          {((props.method === "Wallet" && props.payChannel === "Paypal" && props.paypalWallet.authorizationToken) ||
            props.paypalWallet.isReady) &&
            window.innerWidth < 800 && (
              <PaypalButton
                setIsloading={setIsloading}
                handleError={handleError}
                setForm={setForm}
                containerId="paypal-button-container-mobile"
                payRequest={payRequest}
                mobile
              />
            )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    method: state.method,
    payChannel: state.payChannel,
    channel: state.channel,
    tradeInfo: state.tradeInfo,
    paymentInfo: state.paymentInfo,
    channelList: state.channelList,
    prepayId: state.prepayId,
    returnUrl: state.returnUrl,
    loadedLibs: state.loadedLibs,
    paypalCreditCard: state.paypalCreditCard,
    paypalWallet: state.paypalWallet,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMethod: (item) => dispatch(setMethod(item)),
    setChannel: (item) => dispatch(setChannel(item)),
    setPayData: (item) => dispatch(setPayData(item)),
    setTradeStatus: (item) => dispatch(setTradeStatus(item)),
    setPaypalCreditCard: (item) => dispatch(setPaypalCreditCard(item)),
    setTradeInfo: (item) => dispatch(setTradeInfo(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
